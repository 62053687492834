<template>
  <ContentWrapper>
    <template v-slot:title>Invite Friends</template>
    <Container class="invite-grid">
      <div class="row">
        <div class="email-wrapper">
            <Input
            class=""
            :noInputValue="true"
            ref="email"
            label="Invite friends by email"
            type="email"
            id="email"
            placeholder="Enter email"
            v-model="emails[0].email"
          />
          <div class="email-count" @click="addEmailInput" style="margin-top:20px">
            <img src="../../../assets/icons/add.svg" alt="add" />
            <p>({{ emails.length }}/10)</p>
          </div>
        </div>
          <div v-for="(item) in emails.slice(1)" :key="item.id" class="email-wrapper">
            <Input
            label=""
            class="email-input"
            type="email"
            placeholder="Enter email"
            @change="addEmailToList"
            v-model="item.email"
          />
          <div class="email-count" @click="addEmailInput">
            <img src="../../../assets/icons/add.svg" alt="add" />
          </div>
          </div>
        <TextArea
          label="Message"
          placeholder="I would like to invite you to this game"
          rows="8"
          :topSpacer="true"
          v-model="message"
        />
        <SubmitButton @onButtonClick="sendInvite">Send Invitation</SubmitButton>
        <div v-if="error" class="error-message">Please field in the message field</div>
      </div>
      <div class="line"></div>
      <div class="invite-by-social">
        <h4>Invite Friends by:</h4>
        <div class="social-media">
            <div>
              <a :href="whatsAppLink" data-action="share/whatsapp/share" target="_blank">
                <img :src="whatsapp" alt="whatsapp icon" class="social-media-icon">
              </a>
            </div>
            <div>
              <a target="popup" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" @click.prevent="onFacebookSharePopout">
                <img :src="facebook" alt="whatsapp icon" class="social-media-icon">    
              </a>
            </div>
            <div>
              <a :href="linkedInLink" target="_blank">
                <img :src="linkedin" alt="whatsapp icon" class="social-media-icon">
              </a>
              
            </div>
          </div>
        <Input
          id="referral-code"
          label="Your referral code"
          type="text"
          placeholder="https://referral.code"
          :noInputValue="true"
          v-model="referralCode"
          readonly
        />
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import Input from "@/components/Input";
import TextArea from "@/components/TextArea";
import SubmitButton from "@/components/SubmitButton";
import WhatsappIcon from "@/assets/icons/whatsapp.png";
import FacebookIcon from "@/assets/icons/facebook.png";
import LinkedinIcon from "@/assets/icons/linkedin.png";

import { invites } from "@/services/api/others.js"
import { userProfile } from "@/services/api/users.js";

import {eventBus} from '@/main.js';
export default {
  components: {
    Input,
    TextArea,
    SubmitButton,
  },
  watch:{
    emails(val){
      if (val.length > 10){
        this.emailCount = 10
      }
    },
    message(val){
      if(val){
        this.error = false
      }
    }
  },
  data() {
    return {
      emails: [{email: ''}],
      whatsapp: WhatsappIcon,
      facebook: FacebookIcon,
      linkedin: LinkedinIcon,
      message: 'Join me in Virtual Trading by Kenanga Futures!',
      error: false,
      referralCode: "",
      referralLink: '',
      whatsAppLink: '',
      linkedInLink: ''
    };
  },
  methods: {
    addEmailToList() {},
    // referralCode() {},
    addEmailInput(){
      if(this.emails.length >= 10){
        return ""
      } else {
        this.emails.push({ email: ''})
      }
    },
    async sendInvite(){
      let emails = this.emails.map(item => item.email).filter(Boolean)

      if(!emails.length) return

      if(!this.message){
        this.error = true
        return
      }
      eventBus.$emit('loader',true);
      try {
        await invites({emails, message: this.message}) 
        eventBus.$emit('loader',false);
        this.emails = [{email: ''}]
        this.message = "Join me in Virtual Trading by Kenanga Futures!"
        this.$swal({
          icon: 'success',
          title: 'Email invitation has been sent',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        })
      } catch (error) {
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          timer: 2000,
          text: error.response.data.userMessage,
          timerProgressBar: true,
        })
      }  
    },
    onFacebookSharePopout(){
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.referralLink}&amp;src=sdkpreparse`,'popup','width=600,height=600')
    },
    fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
       return '%' + c.charCodeAt(0).toString(16);
      });
    }
  },
  async mounted(){
    const req = await userProfile()
    this.referralCode = req.data.response.user.referral_code;
    this.referralLink = req.data.response.user.referral_url;
    this.whatsAppLink = `https://api.whatsapp.com/send?text=${this.referralLink}`;
    this.linkedInLink = `https://www.linkedin.com/shareArticle?mini=true&url=${this.fixedEncodeURIComponent(this.referralLink)}`
  }
};
</script>

<style lang="scss" scoped>

.content-container{
  min-height: calc(100vh - 160px);

  @media screen and (max-width: 768px) {
    min-height: initial;
    height: calc(100vh - 90px);
  }

  @media screen and (max-width: 768px) {
    min-height: initial;
    height: calc(100vh - 70px);
  }

  @include respond(phone){
    min-height: initial;
    height: calc(100vh - 60px - 58px);
  }

}

.invite-grid {
  display: grid;
  grid-template-columns: 2fr 1px 1.2fr;
  grid-template-areas: "form line social";
  .row {
    grid-area: form;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    margin: 1rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }


    .email-wrapper {
      position: relative;
      padding-right: 30px;
      display: flex;
      align-items: center;

      @include respond(tab-port){
        padding-right: 0;
      }
      .email-input{
        max-width: calc(100% - 102px);
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
      }

      .email-count {
        display: flex;
        p{
          display: inline-block;
          width: 40px;
        }
      }

      img {
        height: 20px;
        width: 20px;
        margin: 0 20px;
        cursor: pointer;
      }

      .input-container {
        flex-grow: 1;
      }
    }
  }
  .line {
    height: 90%;
    grid-area: line;
    justify-self: center;
    align-self: center;
    border-left: 1px solid #eee;
    margin-right: 0.5rem;
  }

  .invite-by-social{
    grid-area: social;
    margin-left: 1rem;
    h4{
      font-weight: normal;
    }
    .social-media{
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-around;
      margin-bottom: 2rem;

      @media screen and (max-width:450px) {
        margin-bottom: 1rem;
      }

      &-icon{
        margin: 2rem auto;

        @media screen and (max-width:450px) {
          margin: 1rem auto;
        }
      }
    }
  }
  .submit-button {
    margin: 4rem auto 0;

    @include respond(tab-land){
      margin: 2rem auto;
    }
  }
}
@include respond(tab-land){
  .invite-grid{
    grid-template-columns: 1fr;
    grid-template-areas: 
    "social" 
    "form";
    grid-template-rows: repeat(2,min-content);

    .invite-by-social{
      margin-left: 0px;
    }

    .row{
      margin: 1rem 0;
    }

    .line{
      display: none;
    }
  }
}
.error-message{
  margin-top: $ten;
  text-align: center;
  color: $red;
}
</style>
